import styled from '@emotion/styled';
import React, { useEffect } from 'react';

const StyledComment = styled.section`
  .utterances {
    max-width: var(--width-primary);
  }
`;

const Comment = () => {
  const containerRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://utteranc.es/client.js';
    script.async = true;
    script.setAttribute('repo', 'changedan/comment');
    script.setAttribute('issue-term', 'pathname');
    script.setAttribute('label', 'comment');
    script.setAttribute('theme', 'github-light');
    script.setAttribute('crossorigin', 'anonymous');

    if (containerRef.current) {
      containerRef.current.appendChild(script);
    } else {
      console.log('Error >> Comment');
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, []);

  return <StyledComment ref={containerRef} />;
};

export default Comment;
