import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import PostHeadInfo, { PostHeadInfoProps } from 'components/PostHeadInfo';
import { globalStyles } from '../styles/GlobalStyle';

const PostHeadWrapper = styled.section`
  width: 100%;
  display: flex;
  margin-top: calc(var(--header-icon) + var(--pad-inner) * 3);

  @media (max-width: ${globalStyles.mobile}) {
    margin-top: calc(var(--header-pad-icon) + var(--pad-inner) * 2);
  }
`;

const PostHead: FunctionComponent<PostHeadInfoProps> = function ({
  title,
  date,
  lastMod,
  categories,
}) {
  return (
    <PostHeadWrapper>
      <PostHeadInfo
        title={title}
        date={date}
        lastMod={lastMod}
        categories={categories}
      />
    </PostHeadWrapper>
  );
};

export default PostHead;
