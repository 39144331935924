import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { globalStyles } from '../styles/GlobalStyle';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
interface Props {
  body: string;
}

const StyledMdxWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--pad-inner);
`;

const MdxWrapper = styled.div`
  width: 100%;
  max-width: var(--width-primary);
  display: flex;
  margin-bottom: calc(var(--inner-secondary-1) * 3.5);
`;

const StyledMdx = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  word-break: break-all;
  font-size: var(--text-post);

  pre {
    code {
      background-color: transparent;
    }
  }

  code {
    font-family: 'Fira Code';
    font-size: calc(var(--text-post) * 0.8);
    background-color: var(--color-code);
    user-select: all;
  }

  pre[class*='language-'] {
    background-color: var(--bg-secondary);
    margin: var(--pad-inner);
    font-family: 'Fira Code';
    font-size: calc(var(--text-post) * 0.8);
    line-height: calc(var(--text-post) * 1.1);
    border-radius: var(--rounded-normal);
    border-color: var(--bg-secondary);
    user-select: all;

    code {
      background-color: transparent;
      font-family: 'Fira Code';
    }
  }

  details {
    margin-left: 0.5rem;

    summary {
      font-weight: bold;
    }
  }

  p {
    line-height: 1.7rem;
  }

  img,
  iframe {
    max-width: 100%;
    margin: 1rem auto;
    display: block;
  }

  p img {
    display: block;
    margin: 0 auto;

    padding: var(--pad-inner);
    display: block;
  }

  img + span,
  iframe + span {
    font-style: italic;
    text-align: center;
    padding: var(--pad-inner);
    padding-top: 0;
  }

  span {
    padding-top: 0;

    :not(pre > div > span) {
      font-size: var(--text-secondary);
      color: #999;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid var(--color-accent-1);
      padding: var(--inner-secondary-2);
      text-align: center;
    }
  }

  h1,
  h2,
  h3 {
    font-weight: 800;
    padding: var(--pad-inner-1) 0;
  }

  * + h1 {
    margin-top: var(--inner-secondary);
  }

  * + h2 {
    margin-top: var(--inner-secondary-2);
  }

  * + h3 {
    margin-top: calc(var(--inner-secondary-2) / 2);
  }

  hr + h1,
  hr + h2,
  hr + h3 {
    margin-top: 0;
  }

  h1 {
    font-size: calc(var(--text-title) * 0.95);
  }

  h2 {
    font-size: calc(var(--text-title) * 0.9);
  }

  h3 {
    font-size: calc(var(--text-title) * 0.85);
  }

  blockquote {
    margin: 12px 0;
    padding: 5px 15px;
    border-left: 2px solid var(--color-secondary);
    font-weight: 800;
    line-height: 20%;
  }

  ol,
  ul,
  li {
    margin-left: 16px;
    padding: 2px 0;
    line-height: 1.6rem;
  }

  hr {
    border: 1px solid var(--color-primary);
    margin: 100px 0;
  }

  // Adjust Link Element Style
  a {
    text-decoration: underline;
    text-decoration-color: var(--color-link);
    text-decoration-thickness: 2px;
    background-color: var(--color-link-bg);

    &:hover {
      background-color: var(--color-link);
    }
  }

  @media (max-width: ${globalStyles.mobile}) {
    font-size: var(--text-post-pad);

    h1 {
      font-size: calc(var(--text-title-pad) * 0.9);
    }

    h2 {
      font-size: calc(var(--text-title-pad) * 0.85);
    }

    h3 {
      font-size: calc(var(--text-title-pad) * 0.8);
    }

    blockquote {
      margin: 18px 0 10px;
      padding: 5px 15px;
      border-left: 2px solid var(--color-accent);
      font-weight: 800;
    }

    code {
      font-size: calc(var(--text-post-pad) * 0.8);
    }

    pre[class*='language-'] {
      font-size: calc(var(--text-post) * 0.6);

      code {
        font-size: calc(var(--text-post) * 0.6);
      }
    }

    p {
      line-height: 1.6rem;
    }
  }
`;

const PostContent = ({ body }: Props) => {
  return (
    <StyledMdxWrapper>
      <MdxWrapper>
        <StyledMdx>
          <MDXRenderer>{body}</MDXRenderer>
        </StyledMdx>
      </MdxWrapper>
    </StyledMdxWrapper>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___lastMod, order: DESC }) {
      nodes {
        id
        body
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          lastMod(formatString: "MMMM D, YYYY")
          title
        }
      }
    }
  }
`;

export default PostContent;
