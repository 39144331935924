import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { globalStyles } from '../styles/GlobalStyle';
import PublishIcon from '../static/assets/publishIcon.svg';
import UpdateIcon from '../static/assets/updateIcon.svg';

export type PostHeadInfoProps = {
  title: string;
  date: string;
  lastMod: string;
  categories: string[];
};

const StyledPostHeadWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: var(--pad-inner);
`;

const StyledPostHead = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--width-primary);
  width: 100%;
  height: 100%;
  color: var(--color-accent);
  grid-gap: var(--pad-inner);
`;

const TitleStyle = styled.div`
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  overflow-wrap: break-word;
  margin-top: auto;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: calc(var(--text-title) * 1.2);
  font-weight: 700;

  @media (max-width: ${globalStyles.mobile}) {
    font-size: calc(var(--text-title-pad) * 1.2);
  }
`;

const StyledPostInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCategoryWrapper = styled.div`
  display: flex;
  grid-gap: 6px;
`;

const StyledCategory = styled.div`
  display: flex;
  padding: var(--category-gap);
  border-radius: var(--rounded-normal);
  background-color: var(--color-secondary);
  font-size: var(--text-category);
  color: var(--color-primary);
`;

const DateStyle = styled.div`
  font-size: 12px;
  color: #4c4c4c;
  display: flex;
  gap: 4px;

  @media (max-width: ${globalStyles.mobile}) {
    font-size: 10px;
  }
`;

const StyledDateItem = styled.span`
  display: flex;
  align-items: center;

  img {
    height: 11px;
    margin-right: 2px;
    padding-top: 1px;
  }

  @media (max-width: ${globalStyles.mobile}) {
    img {
      height: 9px;
      margin-right: 2px;
    }
  }
`;

const PostHeadInfo: FunctionComponent<PostHeadInfoProps> = function ({
  title,
  date,
  lastMod,
  categories,
}) {
  return (
    <StyledPostHeadWrapper>
      <StyledPostHead>
        <StyledPostInfo>
          <StyledCategoryWrapper>
            {categories.map((category) => (
              <StyledCategory key={category}>{category}</StyledCategory>
            ))}
          </StyledCategoryWrapper>
          {date === lastMod ? (
            <DateStyle>
              <StyledDateItem>
                <img src={PublishIcon} alt="PublishDate" />
                {date}
              </StyledDateItem>
            </DateStyle>
          ) : (
            <DateStyle>
              <StyledDateItem>
                <img src={UpdateIcon} alt="LastModifiedDate" />
                {lastMod}
              </StyledDateItem>
            </DateStyle>
          )}
        </StyledPostInfo>
        <TitleStyle>{title}</TitleStyle>
      </StyledPostHead>
    </StyledPostHeadWrapper>
  );
};

export default PostHeadInfo;
